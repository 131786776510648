
import { BIconChevronRight } from 'bootstrap-vue'
const cfg = require(`../configs/${process.env.NODE_ENV}.json`)
// import { mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    BIconChevronRight
  },
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  async fetch () {
    const productGroupCategories = await fetch(cfg.axios.apiBaseURL + 'product-group-categories').then(res => res.json())
    const articles = await fetch(cfg.axios.apiBaseURL + 'articles?limit=4&forHomepage=1').then(res => res.json())

    this.productGroupCategories = productGroupCategories[0]
    this.articles = articles.data
    // console.log(this.productGroupCategories, this.articles)
  },
  data () {
    return {
      slide: 0,
      articles: [],
      sliding: null,
      productGroupCategories: []
    }
  },
  fetchOnServer: true,
  methods: {
    viewArticle (itemId) {
      return { name: 'articles-id', params: { id: itemId } }
    },
    slideLeft (el, width) {
      const content = document.querySelector(el)
      const translate = 0
      content.scrollLeft -= width
      content.style.transform = 'translateX(' + translate + 'px' + ')'
    },
    slideRight (el, width) {
      const content = document.querySelector(el)
      const translate = 0
      content.scrollLeft += width
      content.style.transform = 'translateX(' + translate + 'px' + ')'
    },
    goBack () {
      this.$router.go(-1)
    }
  },
  head () {
    return {
      title: 'ไม่พบหน้าดังกล่าว'
    }
  }
}
