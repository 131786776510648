
import { mapGetters, mapState } from 'vuex'
import { BIconX } from 'bootstrap-vue'
import NavbarLine from '~/components/layout/navbar-line.vue'
import FooterLine from '~/components/layout/footer-line.vue'

export default {
  components: {
    NavbarLine,
    FooterLine,
    BIconX
  },
  data () {
    return {
      loadingModal: false
      // loadingModalCheckprice: false
    }
  },
  computed: {
    ...mapState(['loading', 'alert']), //, 'loadingprice'
    ...mapGetters(['isAuthenticated'])
  },
  watch: {
    loading (val) {
      this.loadingModal = val
    }
  },
  mounted () {

  },
  methods: {
    hideAlert () {
      this.$store.commit('setAlert', { show: false, message: '' })
    },
    hideLoading () {
      this.$store.commit('hideLoading')
    }
  }
}
